<template>
  <div class="add-facility mb-3 pb-3">
    <LoadingOverlay v-if="isLoginFetching" />
    <h3 class="pb-3 px-1 mb-0 text-primary">Facility Registration</h3>
    <ValidationObserver ref="vcheckFacility" v-slot="{ handleSubmit }">
      <form id="vcheckFacility" @submit.prevent="handleSubmit(onSubmit)">
        <CCard class="mt-2">
          <CCardBody>
            <CRow>
              <CCol lg="12">
                <CRow class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Facility Email</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="facility.email"
                        :error="errors[0]"
                        @input="handleInput"
                        autocomplete="off"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="12">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Unique Id</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="unique_id"
                        :value="facility.unique_id"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <h6>Note:</h6>
            <p class="ml-3">
              Once registered, selected users can access the system by using the
              login fields on the landing page.
            </p>
          </CCardBody>
        </CCard>
        <div class="text-center mx-0 mt-4 pt-4 action-area">
          <!-- <CButton
            variant="outline"
            size="lg"
            type="button"
            color="primary"
            shape="square"
            class="px-5 m-1 mt-3"
            @click="resetForm"
            >Clear All</CButton
          > -->
          <CButton
            variant="outline"
            type="button"
            size="lg"
            color="primary"
            shape="square"
            class="px-4 mx-2"
            @click="cancelForm"
            >Cancel</CButton
          >
          <CButton
            type="submit"
            size="lg"
            color="primary"
            shape="square"
            class="px-4 mx-2"
            >Submit</CButton
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });

export default {
  components: {
    TextInput,
  },
  data() {
    return {
      facility: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["isLoginFetching"]),
  },
  methods: {
    handleInput(name, value) {
      // let hasError = {
      //   phone: "phone",
      // };
      // if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
      //   Vue.set(this.facility, name, value);
      //   Vue.set(this.payload, name, value);
      // }
      // if (name === hasError.phone && value === "") {
      //   this.clearFieldErrors(name, hasError);
      // }
      Vue.set(this.facility, name, value);
      Vue.set(this.payload, name, value);
    },
    cancelForm() {
      this.$router.go(-1);
    },
    async onSubmit() {
      const isValid = await this.$refs.vcheckFacility.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.$emit("submit", this.payload);
    },
  },
};
</script>
