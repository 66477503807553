<template>
  <div class="container-md col-lg-8 col-md-8 mb-3">
   <CheckFacility :customer="2" @submit="CheckFacility" /> 
  </div>
</template>
<script>

import CheckFacility from "@/containers/Facility/CheckFacilityUser"
import { mapActions } from "vuex";
export default {
  components: {
    CheckFacility
  },
  name: "FacilityCheck",
  methods: {
    ...mapActions(["checkFacility"]),
    CheckFacility(payload) {
      this.checkFacility(payload);
    },
  },
};
</script>
